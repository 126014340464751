import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT IS A 1031 LOAN, OR 1031 EXCHANGE?",
      answer: (
        <>
          <p>
            A 1031 exchange refers to the sale of one investment property and
            the purchase of another. When you follow the IRS rules for the swap,
            you can defer taxes on capital gains
          </p>
        </>
      ),
    },
    {
      question: "HOW DOES A 1031 EXCHANGE WORK?",
      answer: (
        <>
          <p>
            Both investments have to be “like-kind” (similar in nature). For
            example, real estate such as houses, buildings, vacant lots, etc.,
            are like-kind investments that you can exchange. Your new
            acquisition must also be of equal or greater value than your
            existing investment.
          </p>
          <p className="text-bold">How Exchanges Are Structured</p>
          <p>
            Depending on your situation, there are four ways to structure an
            exchange:
          </p>
          <ul className="list-disc pl-6">
            <li>
              Simultaneous Exchange: Buying and selling at, or very close to,
              the same time
            </li>
            <li>Delayed Exchange: Buying and selling within 180 days</li>
            <li>Reverse Exchange: Buy first, sell later</li>
            <li>
              Construction or Improvement Exchange: Typically used when vacant,
              underdeveloped land is acquired
            </li>
          </ul>
        </>
      ),
    },
    {
      question:
        "WHAT IS THE BENEFIT OF A 1031 EXCHANGE VS. SELLING A PROPERTY?",
      answer: (
        <>
          <p>
            When you sell an investment property you’re required to pay a
            capital gains tax, which eats into your sale profit.{" "}
          </p>

          <p>
            The benefit of a 1031 exchange is that it allows you to sell an
            investment property, buy another one and defer the capital gains
            tax. The tax would only be due when you sell the property.
          </p>

          <p>
            The result is that you get to keep more of the sale profit, which
            you’re required to reinvest in order to gain the advantage of the
            deferred capital gains tax.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="1031 Loans & Exchange Mortgage | Accel Home Loans"
        description="If you own an investment property, you can swap it out for another one and defer the capital gains tax through a 1031 exchange. Learn more here!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>1031 Loan: The Smart Way To Grow An Investment</h1>
              <p>
                Did you know that if you own an investment property, you can
                swap it out for another one and defer the capital gains tax
                through a 1031 exchange? It’s a great way to acquire a more
                valuable California investment property, or even consolidate
                multiple properties, without immediately owing taxes.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/7.0 1031 Exchange/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>Why a 1031 Exchange Makes Sense</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">Defer Taxes</h3>
                <p className="mb-0">
                  Get a tax advantage—defer the capital gains tax you’d
                  otherwise pay if selling and buying.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">Increase Your Cash Flow</h3>
                <p className="mb-0">
                  Exchange a property that isn’t generating income for one that
                  has a positive cash flow.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Free Up Capital</h3>
                <p className="mb-0">
                  Leverage the proceeds of a 1031 exchange to invest in other
                  assets or improve a property.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">Consolidate</h3>
                <p className="mb-0">
                  Exchange multiple properties for a single, higher-value
                  property that’ll make you more money.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>What Are the Requirements?</h2>
              <p>
                First, the investment property you acquire in the exchange must
                be both like-kind and of equal or greater value. And second, any
                of the sale proceeds must be invested (you can’t pocket the
                extra cash). Here’s who can qualify for a 1031 exchange:
              </p>
              <ul className="styled-list-arrow">
                <li>Individuals</li>
                <li>C corporations</li>
                <li>S corporations</li>
                <li>Partnerships (general or limited)</li>
                <li>Limited liability companies</li>
                <li>Trusts</li>
                <li>Any other taxpaying entity</li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/7.0 1031 Exchange/1031 Exchange.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
